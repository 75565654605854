<template>
  <div :class="$style.page">
    <div :class="$style.panel">
      <adw-button color="primary" @click="submit"> Cохранить </adw-button>
    </div>
    <div :class="$style.treeHeader">
      <div :class="$style.category">Название</div>
      <div :class="$style.content">
        <div :class="$style.checkbox">Исключить из Sitemap</div>
      </div>
    </div>
    <el-tree
      :data="sitemapTree"
      :class="$style.tree"
      accordion
      highlight-current
    >
      <template slot-scope="{ node, data }">
        <div
          v-if="isDynamic(node.parent.data.link)"
          :class="$style.information"
        >
          <div :class="$style.content">
            {{ getNotification(node.parent.data.link) }}
          </div>
        </div>
        <div v-else :class="$style.node">
          <div :class="$style.link">
            {{ data.link }}
          </div>
          <div :class="$style.content">
            <div :class="$style.checkbox" @click.stop>
              <el-checkbox
                v-model="data.isExcluded"
                size="large"
                @change="toggleNode(node)"
              />
            </div>
          </div>
        </div>
      </template>
    </el-tree>
  </div>
</template>
<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'
export default {
  mixins: [notifications],
  data() {
    return {
      sitemapTree: []
    }
  },
  async mounted() {
    await this.getSitemapTree()
    // TODO: remove after receiving dynamic data
    this.sitemapTree[0]?.children?.forEach((node) => {
      if (this.isDynamic(node.link)) {
        node.children = [
          {
            link: '',
            isExcluded: false
          }
        ]
      }
    })
  },
  methods: {
    async getSitemapTree() {
      const loading = this.$loading({ lock: true })
      const { value, error } =
        await delivery.ContentServiceCore.SeosActions.getSitemap()
      loading.close()
      if (error) return
      this.sitemapTree = [value]
    },
    async submit() {
      const loading = this.$loading({ lock: true })

      const data = this.sitemapTree[0]
      const { error } =
        await delivery.ContentServiceCore.SeosActions.updateSitemap(data)
      loading.close()

      if (error) {
        this.showNotification('Ошибка сохранения sitemap', 'error')
        return
      }

      this.showNotification('Sitemap успешно сохранен', 'success')

      await this.getSitemapTree()
    },
    getNotification(link) {
      switch (link) {
        case '/catalogue':
          return 'Для того, чтобы исключить продукт, измените статус отображения на странице продукта'
        case '/brands':
          return 'К сожалению, на данный момент невозможно исключить бренд'
        case '/articles':
          return 'Для того, чтобы исключить статью, измените статус отображения на странице статьи'
        case '/categories':
          return 'Для того, чтобы исключить категорию, измените статус отображения на странице категории'
        case '/assortments':
          return 'Для того, чтобы исключить ассортимент, измените статус отображения на странице ассортимента'
        case '/promotions':
          return 'Для того, чтобы исключить акцию, измените статус отображения на странице акции'
      }
    },
    isDynamic(link) {
      return [
        '/catalogue',
        '/brands',
        '/articles',
        '/categories',
        '/assortments',
        '/promotions'
      ].some((item) => item === link)
    },
    toggleNode(node) {
      const parentExcluded = node.data.isExcluded
      const selectNode = (node) => {
        if (node.childNodes) {
          node.childNodes.forEach((child) => {
            selectNode(child)
          })
        }
        node.data.isExcluded = parentExcluded
      }
      node.parent.data.isExcluded = !node.parent.childNodes.some((child) => {
        return !child.data.isExcluded
      })
      selectNode(node)
    }
  }
}
</script>
<style lang="scss" module>
.page {
  padding: 1rem;
}
.panel {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
.treeHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
  .content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 70vw;
  }
}
.information {
  width: 100%;
  display: flex;
  justify-content: center;
  .content {
    text-align: center;
  }
}
.node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1rem;
  padding-right: 0.5rem;
  .content {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
}
</style>
